import React from "react"
import SimpleSlider from "../components/simpleslider"
import { GrRestaurant } from "react-icons/gr"
import Layout from "../components/layout"
import Seo from "../components/seo"

const recomchefe = () => {
  return (
    <Layout>
      <Seo title="Sugestão do Chefe" defer={false} />
      <div className="w-full mx-auto flex flex-col justify-center items-center">
        <div className="flex flex-col w-full h-20 bg-gray-200 justify-center items-center text-md">
          <h1>Escolhemos o melhor para si!</h1>
          <span className="text-xs font-extralight">
            Coma bem, viva melhor!
          </span>
        </div>
        <div className="mt-9 w-4/5 h-5/6 mb-9 mx-auto ">
          <div>
            <h1 className="text-xs font-bold text-red-700  flex justify-items-start items-center">
              <GrRestaurant className="mr-1" />
              Sugestão Do Chefe!
            </h1>
          </div>

          <section>
            <SimpleSlider />
          </section>
        </div>
      </div>
    </Layout>
  )
}

export default recomchefe
